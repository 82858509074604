<template>
  <div class="app-container">
    <div class="content_data">
      <div style="
          display: flex;
          align-items: center;
          width: 98%;
          margin: 0 auto;
          height: 70px;
        ">
        <div style="width: 8%">统计</div>
        <div class="each">
          <div class="icon_bg">
            <i class="iconfont icon-gongsi1"></i>
          </div>
          <div style="margin-left: 5px; cursor: pointer" @click="getType(0)">
            <div style="margin-bottom: 5px">企业总数</div>
            <a>
              <span>{{ sum }}</span>家
            </a>
          </div>
        </div>
        <div class="each">
          <div class="icon_bg">
            <i class="iconfont icon-zichan"></i>
          </div>
          <div style="margin-left: 5px; cursor: pointer" @click="getType(1)">
            <div style="margin-bottom: 5px">有税款</div>
            <a>
              <span>{{ sumWithTax }}</span>家
            </a>
          </div>
        </div>
      </div>
    </div>
    <div class="content_select">
      <div>
        账期：
        <qzf-search-period v-model:period="listQuery.beginPeriod" @success="getList"></qzf-search-period>
        <span style="margin: 0 3px">至</span>
        <qzf-search-period v-model:period="listQuery.endPeriod" @success="getList"></qzf-search-period>
        <el-input placeholder="请输入企业名称/编号" v-model="listQuery.name" style="width: 160px; margin: 0 5px" size="small"
          @keyup.enter="getList" clearable /><el-button type="primary" @click="getList" size="small"
          style="margin-right: 5px" icon="Search">搜索</el-button>
        <search @success="getList" @cancel="cancel">
          <el-form style="margin: 11px 0px 0px 15px; width: 300px" label-width="95px">
            <el-form-item label="纳税人类型：">
              <selecttaxtype v-model:type="listQuery.type" style="width: 140px"></selecttaxtype>
            </el-form-item>
            <el-form-item label="税局：">
              <selectcity v-model:citys="listQuery.districtCode" style="width: 140px"></selectcity>
            </el-form-item>
            <el-form-item label="选择人员：">
              <selectuser code="bookkeeping" v-model:userId="listQuery.userId" style="width: 140px"></selectuser>
            </el-form-item>
            <el-form-item label="公司类型：">
              <el-select v-model="listQuery.companyType" size="small" placeholder="请选择公司类型" filterable clearable
                style="width:140px">
                <el-option v-for="item in typeOptions" :key="item.value" :label="item.label"
                  :value="item.value"></el-option>
              </el-select>
            </el-form-item>
          </el-form>
        </search>
        <span style="margin-left: 5px"><el-switch v-model="listQuery.not0" active-text="只显示有税款" inactive-text=""
            :active-value="1" :inactive-value="0" @change="getList" size="small"></el-switch></span>
      </div>

      <div>
        <colSetting name="税款统计" btnName="显示列" @arrangeInfo="getInfo" @load="loadingV" />
        <el-button type="primary" size="small" @click="copyTax" :disabled="!$buttonStatus('sktj_fzsk')">
          <el-icon>
            <DocumentCopy />
          </el-icon> <span>复制税款</span>
        </el-button>
        <el-button @click="daochu()" type="primary" size="small" :disabled="!$buttonStatus('sktj_dc')">
          <el-icon>
            <FolderOpened />
          </el-icon> <span>导出</span>
        </el-button>
      </div>
    </div>
    <div>
      <el-table :data="list" border style="width: 100%" v-loading="loading" :height="contentStyleObj"
        @select="handleSelectionChange" stripe @sort-change="sortChange" ref="tableScroll" id="tableLazyLoad"
        @select-all="handleSelectionChangeAll">
        <template #empty>
          <el-empty :image-size="150" description="没有数据"></el-empty>
        </template>
        <el-table-column align="center" prop="id" type="selection" width="55" />
        <el-table-column label="编号" align="center" width="80" fixed="left" prop="sort" sortable="custom">
          <template #default="scope">
            <TableSortCell :row="scope.row" idKey="comId" />
          </template>
        </el-table-column>
        <el-table-column prop="name" label="企业名称" min-width="280" show-overflow-tooltip fixed="left">
          <template #default="scope">
            <TagNameCopy :row="scope.row" idKey="comId" :showAuthIcon="false"></TagNameCopy>
          </template>
        </el-table-column>
        <el-table-column prop="districtName" label="税局" width="65" align="center">
          <template #default="scope">
            <span>{{ $cityFilter(scope.row.district) }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="total" label="总税金" sortable align="right" min-width="90"
          v-if="arrangeInfo.includes('总税金')" />
        <el-table-column prop="zzs" label="增值税" sortable align="right" min-width="90"
          v-if="arrangeInfo.includes('增值税')" />
        <el-table-column prop="fjs" label="附加税" sortable align="right" min-width="90"
          v-if="arrangeInfo.includes('附加税')" />
        <el-table-column prop="deed" label="行为税" sortable align="right" min-width="90"
          v-if="arrangeInfo.includes('行为税')" />
        <el-table-column prop="gs" label="个税" sortable show-overflow-tooltip align="right" min-width="90" v-if="arrangeInfo.includes('个税')">
          <template #default="scope">
            {{ scope.row.gs }}
            <span v-if="scope.row.gsFjs && scope.row.gsFjs != '0' && scope.row.gsFjs != '0.00'">+{{ scope.row.gsFjs
              }}</span>
            <span v-if="scope.row.gsOnly && scope.row.gsOnly != '0' && scope.row.gsOnly != '0.00'">+{{ scope.row.gsOnly
              }}</span>
          </template>
        </el-table-column>

        <el-table-column prop="qysds" label="企业所得税" sortable align="right" min-width="115"
          v-if="arrangeInfo.includes('企业所得税')" />
        <!-- <el-table-column
          prop="sb"
          label="社保"
          sortable
          align="right"
          min-width="90"
          v-if="arrangeInfo.includes('社保')"
        /> -->
        <el-table-column prop="dqde" label="定期定额" sortable align="right" min-width="95"
          v-if="arrangeInfo.includes('定期定额')" />
        <el-table-column prop="scjysds" label="生产经营所得税" sortable align="right" min-width="140"
          v-if="arrangeInfo.includes('生产经营所得税')" />
        <el-table-column prop="whsyjss" label="文化事业建设费" sortable align="right" min-width="140"
          v-if="arrangeInfo.includes('文化事业建设费')" />
        <el-table-column prop="ghjf" label="工会经费" sortable align="right" min-width="100"
          v-if="arrangeInfo.includes('工会经费')" />
        <el-table-column prop="ljf" label="垃圾费" sortable align="right" min-width="90"
          v-if="arrangeInfo.includes('垃圾费')" />
        <el-table-column prop="xfs" label="消费税" sortable align="right" min-width="90"
          v-if="arrangeInfo.includes('消费税')" />
        <el-table-column prop="cbj" label="残保金" sortable align="right" min-width="90"
          v-if="arrangeInfo.includes('残保金')" />
        <el-table-column prop="sl" label="水利基金" sortable align="right" min-width="100"
          v-if="arrangeInfo.includes('水利基金')" />
        <el-table-column prop="hbs" label="环保税" sortable align="right" min-width="90"
          v-if="arrangeInfo.includes('环保税')" />
        <el-table-column prop="qt" label="其他税种合计" sortable align="right" min-width="140"
          v-if="arrangeInfo.includes('其他税种合计')" />
        <el-table-column prop="cn_name" label="负责人" align="left" fixed="right" min-width="130">
          <template #default="scope">
            <div v-if="scope.row.cn_name">
              <DynamicPopover>
                <template #reference>
                  <div @click="poper(scope.row.comId)"
                    style="width: 90%;cursor:pointer;white-space: nowrap;overflow: hidden;text-overflow: ellipsis;">
                    {{ scope.row.cn_name }}
                  </div>
                </template>
                <KjTable :list="gridData" />
              </DynamicPopover>
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="pagination">
      <qzf-pagination v-show="total > 0" :total="total" v-model:page="listQuery.page" v-model:limit="listQuery.limit"
        @pagination="getList()" type="taxStatistics" />
    </div>
  </div>
</template>

<script>
import { currentAccountPeriod } from "@/utils";
import {
  companyTaxAmountList,
  companyUserList,
} from "@/api/company";
import { wbVoucherFxOther } from "@/api/export";
import selectcity from "@/components/Screening/selectcity";
import selecttaxtype from "@/components/Screening/selecttaxtype";
import TagNameCopy from "@/components/table-cell-render/company-name/TagNameCopy";
import TableSortCell from "@/components/table-cell-render/table-sort-cell/TableSortCell";
import DynamicPopover from '@/components/DynamicPopover/DynamicPopover'
import KjTable from '@/components/table-cell-render/KjTable'
import { COMPANY_TYPE_OPTION } from '@/utils/commonData.js'

export default {
  name: "taxStatistics",
  components: {
    selectcity,
    selecttaxtype,
    TableSortCell,
    TagNameCopy,
    DynamicPopover,
    KjTable
  },
  props: {},
  data() {
    return {
      typeOptions: COMPANY_TYPE_OPTION,
      listQuery: {
        page: 1,
        limit: 20,
        name: "",
        beginPeriod: currentAccountPeriod(),
        endPeriod: currentAccountPeriod(),
        type: "",
        not0: 0,
        companyType: ''
      },
      loading: false,
      list: [],
      total: 0,
      sum: 0,
      sumWithTax: 0,
      ids: [],
      arrangeInfo: [],
      selects: [],
      tableData: [],
      totalPage: 0,
      pageSize: 50,
      currentPage: 1,
      allChecked: false,
      gridData: [], //负责人数据
    };
  },
  created() {
    this.listQuery.limit = localStorage.getItem("taxStatistics")
      ? localStorage.getItem("taxStatistics") * 1
      : 20;
    this.contentStyleObj = this.$getHeight(275);
  },
  mounted() {
    //this.getList();
  },

  methods: {
    // 列表点击
    poper(val) {
      this.gridData = []
      companyUserList({ comId: val }).then((res) => {
        this.gridData = res.data.data.list;
      });
    },
    // 重置
    cancel() {
      let originLimit = this.listQuery.limit;
      this.listQuery = {
        page: 1,
        limit: originLimit,
        name: "",
        type: null,
        userId: null,
        districtCode: null,
        beginPeriod: currentAccountPeriod(),
        endPeriod: currentAccountPeriod(),
        not0: 0,
        companyType: ''
      };
      this.getList();
    },
    loadingV() {
      this.loading = true;
    },
    getInfo(e) {
      this.arrangeInfo = e;
      this.getList();
    },
    getList() {
      this.loading = true;
      companyTaxAmountList(this.listQuery).then((res) => {
        this.loading = false;
        if (res.data.msg == "success") {
          this.sum = res.data.data.sum;
          this.sumWithTax = res.data.data.sumWithTax;
          this.tableData = res.data.data.list ? res.data.data.list : [];
          this.total = res.data.data.total;
          this.setScroll();
        }
      });
    },
    setScroll() {
      //先重置状态和数组
      this.allChecked = false;
      this.selects = [];
      this.ids = [];
      //计算滚动页数
      this.totalPage = this.tableData.length / this.pageSize;
      if (this.tableData.length % this.pageSize == 0) {
        this.totalPage = this.tableData.length / this.pageSize;
      } else {
        this.totalPage = parseInt(this.tableData.length / this.pageSize) + 1;
      }
      this.list = this.tableData.slice(0, this.currentPage * this.pageSize);
      this.lazyLoading();
    },
    lazyLoading() {
      let lazy = document.getElementById("tableLazyLoad");
      let dom = lazy.querySelector(".el-scrollbar__wrap");
      let that = this;
      dom.addEventListener("scroll", function () {
        const scrollDistance =
          dom.scrollHeight - dom.scrollTop - dom.clientHeight;
        if (scrollDistance <= 5) {
          if (that.currentPage < that.totalPage) {
            that.currentPage++;
            that.list = that.tableData.slice(
              0,
              that.currentPage * that.pageSize
            );
            if (
              that.allChecked &&
              that.selects.length == that.tableData.length
            ) {
              that.$refs.tableScroll.toggleAllSelection();
              that.allChecked = true;
            } else if (that.selects.length != 0) {
              that.selects.map((v) => {
                that.list.map((item) => {
                  if (item.comId == v.comId) {
                    that.$nextTick(() => {
                      that.$refs.tableScroll.toggleRowSelection(item, true);
                    });
                  }
                });
              });
            }
          }
        }
      });
    },
    getIds() {
      let ids = [];
      this.selects.map((v) => {
        ids.push(v.comId);
      });
      this.ids = ids;
    },
    // 筛选打印
    handleSelectionChange(val) {
      if (this.allChecked && this.list.length != this.tableData.length) {
        let currentIndex = 0;
        this.list.map((v, i2) => {
          val.map((e) => {
            if (e.comId == v.comId) {
              currentIndex = i2;
            }
          });
        });
        let arr = this.tableData.slice(currentIndex + 1, this.tableData.length);
        let newArr = [...val, ...arr];
        this.selects = newArr;
      } else {
        this.selects = val;
      }
      this.getIds();
    },
    handleSelectionChangeAll(e) {
      if (e.length != 0) {
        this.selects = this.tableData;
        this.allChecked = true;
        this.getIds();
      } else {
        this.selects = [];
        this.ids = [];
        this.allChecked = false;
      }
    },
    //筛选
    getType(flag) {
      this.listQuery.not0 = flag;
      this.getList();
    },
    //导出
    daochu() {
      let param = {
        outType: "excel",
        exportType: "company_tax_list",
        beginPeriod: this.listQuery.beginPeriod,
        endPeriod: this.listQuery.endPeriod,
        query: {
          comIds: this.ids,
          endPeriod: this.listQuery.endPeriod,
          beginPeriod: this.listQuery.beginPeriod,
          not0: this.listQuery.not0,
        },
      };
      if (this.ids.length != 0) {
        wbVoucherFxOther(param).then((res) => {
          if (res.data.msg == "success") {
            window.open(res.data.data.url);
          }
        });
      } else {
        param.query.comIds = [];
        this.$confirm("是否全部导出", "提示", {
          confirmButtonText: "是",
          cancelButtonText: "否",
          type: "warning",
        }).then(() => {
          wbVoucherFxOther(param).then((res) => {
            if (res.data.msg == "success") {
              window.open(res.data.data.url);
            }
          });
        });
      }
    },
    //复制税款
    copyTax() {
      if (this.selects.length == 0) {
        this.$qzfMessage("请选择公司", 1);
        return;
      }
      // 基础字符串数组
      let rows = [];
      // 使用对象键值对来简化税种的判断和拼接
      const taxes = {
        zzs: "增值税",
        fjs: "附加税",
        deed: "行为税",
        gs: "个税",
        qysds: "企业所得税",
        sb: "社保",
        dqde: "定期定额",
        scjysds: "生产经营所得税",
        whsyjss: "文化事业建设费",
        ghjf: "工会经费",
        ljf: "垃圾费",
        xfs: "消费税",
        cbj: "残保金",
        sl: "水利基金",
        hbs: "环保税",
        gsOnly: "全年一次性奖金",
        gsFjs: "劳务报酬"
      };
      // 遍历selects数组
      this.selects.forEach((v) => {
        let row = `您的企业「${v.name}」${this.listQuery.beginPeriod}至${this.listQuery.endPeriod}账期`;
        if (v.total && v.total !== "0.00") {
          row += "税款如下：";
          Object.keys(taxes).forEach((key) => {
            const taxName = taxes[key];
            const taxValue = v[key];
            if (taxValue && taxValue !== "0" && taxValue !== "0.00" && taxName != '社保') {
              row += `\r${taxName}：${taxValue}`;
            }
          });
          row += `\r总计：${v.total}`;
        } else {
          row += "账期暂无税款";
        }
        rows.push(row);
      });
      // 将所有行拼接成最终字符串，注意最后的提示信息
      let str =
        rows.join("\r") +
        `\r${this.listQuery.beginPeriod}至${this.listQuery.endPeriod}账期税款如上，请确认，如有疑问请随时沟通`;
      this.$copyComName(str);
    },
    // 表格排序
    sortChange(data) {
      if (data.column.label == "编号") {
        if (data.order == "descending") {
          this.listQuery.desc = 1;
        } else {
          this.listQuery.desc = 0;
        }
        this.getList();
      }
    },
  },
};
</script>

<style scoped lang="scss">
.content {
  width: 98%;
  margin: 0 auto;
}

.content_data {
  background-color: #d8ecff;
}

.each {
  width: 15%;
  display: flex;
  align-items: center;
  font-size: 14px;
  color: #333;
}

.each span {
  font-size: 16px;
  font-weight: 600;
  color: #000;
}

.each a {
  text-decoration-line: underline;
  text-decoration-color: #141720;
}

.icon_bg {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  background-color: #aedcfb;
  text-align: center;
  line-height: 35px;
}

.iconfont {
  font-size: 18px;
  //color: var(--themeColor, #17a2b8);
  color: #17a2b8;
}

.content_select {
  margin: 10px 0;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
</style>
